import React, { useState, useEffect } from 'react';
import { BASE_URL, IP } from './config';

const SubClipModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    maxWidth: '1000px',
    width: '100%',
    position: 'relative',
  },
  videoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
  },
  videoItem: {
    position: 'relative',
    width: '100%',
    height: '150px',
    overflow: 'hidden',
    borderRadius: '8px',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    pointerEvents: 'none',
  },
  regenerateButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
    color: '#333',
  },
  regenerateIcon: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    fontSize: '18px',
  },
};

function SubClipModal({ isOpen, onClose, video,  youtube_video_id, dividedClips, imageText, chosenYoutubeVideoTitle, fetchSentence, setActiveTab}) {
  console.log("dividedClips",dividedClips)
  let buckets = dividedClips || [];
  const [bucketIndex, setBucketIndex] = useState(0);
  const token = localStorage.getItem('access_token_orion_editor');


  useEffect(() => {
    let players = [];

    const initializePlayers = () => {
      if (buckets.length === 0) return;
      players = [];
      buckets[bucketIndex].forEach(([start, end], index) => {
        const player = new window.YT.Player(`player-${index}`, {
          videoId: youtube_video_id,
          playerVars: {
            start,
            end,
            autoplay: 1,
            mute: 1,
            controls: 0,
            rel: 0,
            modestbranding: 1,
            showinfo: 0,
            loop: 0,
          },
          events: {
            onStateChange: (event) => handlePlayerStateChange(event, start, end),
          },
        });
        players.push(player);
      });
    };

    const destroyPlayers = () => {
      players.forEach((player) => player.destroy());
      players = [];
    };

    const handlePlayerStateChange = (event, start, end) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        event.target.seekTo(start);
      }
    };

    if (isOpen) {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);

        tag.onload = () => {
          window.onYouTubeIframeAPIReady = () => {
            initializePlayers();
          };
        };
      } else {
        initializePlayers();
      }
    }

    return () => {
      if (window.YT) {
        destroyPlayers();
      }
    };
  }, [isOpen, video, bucketIndex]);

  if (!isOpen) return null;

  const handleRegenerateClick = () => {
    setBucketIndex((prevIndex) => (prevIndex + 1) % buckets.length);
  };


  
  const handleSubClipPick = async (start, end) => {
    console.log(`Start: ${start}, End: ${end}`);
    try {

      const response = await fetch(`${BASE_URL}/choose_subclip`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ youtube_video_id: youtube_video_id, start: start , end : end , image_text_id: imageText.id, youtube_video_title: chosenYoutubeVideoTitle}),
      });
      if (response.status === 403) {
        window.location.href = '/login';
        return;
      }

      const data = await response.json();
    } catch (error) {
      console.error('Error fetching subclips.', error);
    } finally {
      //
    }
    setBucketIndex(0);
    onClose();
    await fetchSentence();
    setActiveTab('main');


  };

  const handleBackClick = () => {
    setBucketIndex(0);
    onClose();
  };



  return (
    <div style={SubClipModalStyles.overlay}>
      <div style={SubClipModalStyles.content} onClick={(e) => e.stopPropagation()}>
        <button style={SubClipModalStyles.regenerateButton} onClick={handleRegenerateClick}>
          Regenerate Clips
          <span style={SubClipModalStyles.regenerateIcon}>🔄</span>
        </button>
        <h3>Video Topic: {video.topic}</h3>
        <h5>
          <i>For the sentence:</i> <span>{imageText.text}</span>
        </h5>

        {buckets.length > 0 ? (
          <div style={SubClipModalStyles.videoGrid}>
            {buckets[bucketIndex].map(([start, end], index) => (
              <div
                key={index}
                style={SubClipModalStyles.videoItem}
                onMouseOver={(e) => (e.currentTarget.style.boxShadow = '0 0 0 5px yellow')}
                onMouseOut={(e) => (e.currentTarget.style.boxShadow = 'none')}
                onClick={() => handleSubClipPick(start, end)}
              >
                <div
                  id={`player-${index}`}
                  style={SubClipModalStyles.iframe}
                ></div>
              </div>
            ))}
          </div>
        ) : (
          <p>No clips available</p>
        )}

        <button style={{ marginTop: '10px' , fontSize: '1.2em'}} onClick={handleBackClick}>
        ◀ Back
        </button>
      </div>
    </div>
  );
}

export default SubClipModal;
