import React, { useState, useEffect } from 'react';
import { BASE_URL, REMOTION_ADDITIONAL_NGINX, REMOTION_SERVER_URL} from './config';
import { Button, Spin} from 'antd';
import SubClipModal from './SubClipModal';

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
};

const buttonStyle = {
  margin: '0 10px',
};

function Modal({ isOpen, onClose, video }) {
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [imageText, setImageText] = useState({});
  const [images, setImages] = useState([]);
  const [myselfImages, setMyselfImages] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [loadingVideoSearch, setLoadingVideoSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('main');
  const [searchTerm, setSearchTerm] = useState('');
  const [youtubeSearchTerm, setYoutubeSearchTerm] = useState('');
  const [showRenderButton, setRenderShowTrue] = useState(false);
  const [json_data_for_editing, setJsonDataForRednering] = useState([{}]);
  const [video_id_for_rendering, setVideoIdForRendering] = useState(0);
  const [videos, setVideos] = useState([]);
  const [PickableClipsFlag, setPickableClipsFlag] = useState(false);
  const [dividedClips, setDividedClips] = useState([]);
  const [openSubClipModal, setOpenSubClipModal] = useState(false);
  const [YoutubeVideoIdSubCliping, setYoutubeVideoIdSubCliping] = useState('')
  const [chosenYoutubeVideoTitle, setChosenYoutubeVideoTitle] = useState('')
  const [gapTimeFrames, setGapTimeFrames] =  useState(0)

  const closeSubClipModal = () => {
    setOpenSubClipModal(false);
  };


  const token = localStorage.getItem('access_token_orion_editor');

  const handleRender = () => {
    if (Object.keys(json_data_for_editing).length === 0) {
      alert('No data available for rendering.');
      return;
    }
  
    setLoading(true);
  

    (async () => {
      try {

        fetch(`${REMOTION_SERVER_URL}/${REMOTION_ADDITIONAL_NGINX}edit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ json_data_for_editing , video_id_for_rendering}),
        });
  
        alert('Video will be sent to your mail.');
        window.location.reload();
        onClose();
      } catch (error) {
        console.error('Error rendering:', error);
        alert('An error occurred while rendering.');
      } finally {
        setLoading(false);
      }
    })();
  };
  
  
  const fetchSentence = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/get_latest_non_selected_image_text`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ video_id: video.id }),
        }
      );
      if (response.status === 403) {
        window.location.href = '/login';
        return;
      }
      const data = await response.json();
      
      if (data.video_completion) {
        alert('Click the render to render the video.');
        setRenderShowTrue(true);
        console.log("data.json_data_for_editing",data.json_data_for_editing)
        setJsonDataForRednering(data.json_data_for_editing);
        setVideoIdForRendering(data.video_id);
        return;
      }
      setGapTimeFrames(data.gap_time_frames)
      setImageText(data.image_text);
      setImages(data.images);
      setMyselfImages(data.myself_images || []);
    } catch (error) {
      console.error('Error fetching sentence:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchSentence();
    }
  }, [isOpen, video.id]);

  if (!isOpen) return null;

  const handleClick = async (index, isMyself = false) => {
    setLoading(true);
    setClickedImageIndex(index);
    const selectedImage = isMyself ? myselfImages[index] : images[index];
    console.log("Selected Image", selectedImage);
    const image_id = selectedImage.id;
    const image_text_id = imageText.id;
  
    try {
      const response = await fetch(`${BASE_URL}/choose_image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          image_text_id,
          image_id,
        }),
      });
  
      if (response.status === 403) {
        window.location.href = '/login';
        return;
      }
  
      if (!response.ok) {
        throw new Error('Failed to choose image');
      }
  
      console.log('Image successfully selected:', { image_text_id, image_id });
  
      await fetchSentence();
    } catch (error) {
      console.error('Error choosing image:', error);
    } finally {
      setLoading(false);
      setClickedImageIndex(null);
      setActiveTab('main');
    }
  };
 
 


  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      alert('Please enter a search term.');
      return;
    }

    setLoading(true);
    try {
      
      const response = await fetch(`${BASE_URL}/get_images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ query_string: searchTerm, image_text_id: imageText.id }),
      });
      if (response.status === 403) {
        window.location.href = '/login';  
        return;
      }

      const data = await response.json();
      setMyselfImages(data.images || []);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleYoutubeSearchInputChange = (e) => {
    setYoutubeSearchTerm(e.target.value);
  };

  const handleYoutubeSearch = async () => {
    setPickableClipsFlag(false);
    setVideos([])
    if (!youtubeSearchTerm.trim()) {
      alert('Please enter a search term.');
      return;
    }

    setLoadingVideoSearch(true);
    try {
      const response = await fetch(`${BASE_URL}/get_videos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ query_string: youtubeSearchTerm, image_text_id: imageText.id }),
      });
      if (response.status === 403) {
        window.location.href = '/login';
        return;
      }

      const data = await response.json();
      console.log('Search results:', data.videos);
      setVideos(data.videos || [])
      console.log("Youtube Call.", youtubeSearchTerm)
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoadingVideoSearch(false);
    }
  };


  const handleYoutubeSearchPick = async (video_id, video_title) => {
    setLoadingVideoSearch(true);
    setPickableClipsFlag(true);
    setYoutubeVideoIdSubCliping(video_id);
    setChosenYoutubeVideoTitle(video_title);
    try {
      console.log("gapTimeFrames",gapTimeFrames)

      const response = await fetch(`${BASE_URL}/get_sub_clips`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ youtube_video_id: video_id, gap_time_seconds:  Math.round(gapTimeFrames/30) + 1}),
      });
      if (response.status === 403) {
        window.location.href = '/login';  
        return;
      }

      const data = await response.json();
      setDividedClips(data.buckets);
      setOpenSubClipModal(true);
    } catch (error) {
      console.error('Error fetching subclips.', error);
    } finally {
      setLoadingVideoSearch(false);
    }
  };


  

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      backgroundColor: 'white',
      padding: '0px 20px',
      paddingBottom: '10px',
      borderRadius: '8px',
      textAlign: 'center',
      maxWidth: '600px',
      width: '100%',
    },
    tabs: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '10px',
    },
    tabButton: (isActive) => ({
      cursor: 'pointer',
      padding: '10px 20px',
      backgroundColor: isActive ? '#007BFF' : '#E0E0E0',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontWeight: isActive ? 'bold' : 'normal',
    }),
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '10px',
      marginTop: '20px',
      maxHeight: '350px',
      overflow: 'auto'
    },
    image: (index) => ({
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      objectFit: 'contain',
      cursor: 'pointer',
      border: clickedImageIndex === index ? '5px solid green' : 'none',
      boxShadow: clickedImageIndex === index ? '0 0 0 5px green' : 'none',
      transition: 'box-shadow 0.3s ease',
    }),
    button: {
      fontSize: '1em',
      marginTop: '10px',
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      marginBottom: '20px',
    },
    input: {
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      flex: 1,
    },
    imageContainer: {
      position: 'relative',
      width: '80%',
      height: '80%',
    },
    imageTitle: {
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      color: 'white',
      padding: '0px',
      borderRadius: '4px',
      fontSize: '0.8em',
      textAlign: 'center',
    },
    querytext: {  marginBottom: '10px',  fontWeight: 'bold',  fontSize: '1.1em',},
  };

  return (
    <><div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h3>
          <span>Video Topic: {video.topic}</span>
        </h3>

        {!showRenderButton && (
          <>
            <h5>
              <i>For the sentence:</i> <span>{imageText.text}</span>
            </h5>

            <div style={modalStyles.tabs}>
              <button
                onClick={() => setActiveTab('main')}
                style={modalStyles.tabButton(activeTab === 'main')}
              >
                Agent Googled Images
              </button>
              <button
                onClick={() => setActiveTab('myself')}
                style={modalStyles.tabButton(activeTab === 'myself')}
              >
                Google Myself
              </button>

              <button
                onClick={() => setActiveTab('youtube_videos')}
                style={modalStyles.tabButton(activeTab === 'youtube_videos')}
              >
                Add Youtube Videos
              </button>
            </div>

            {loading ? (
              <Spin spinning={loading} fullscreen tip="Getting Images..." size="small" />
            ) : activeTab === 'main' ? (
              <div>
                <div style={modalStyles.queryText}>
                  <i>The Agent Searched the term</i> : {imageText.query_string}
                </div>

                <div style={modalStyles.grid}>
                  {images.map((image, index) => (
                    <div key={index} style={modalStyles.imageContainer}>
                      <img
                        src={image.link}
                        alt={`image-${index}`}
                        style={modalStyles.image(index)}
                        onClick={() => handleClick(index)}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = '0 0 0 5px yellow')}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow =
                          clickedImageIndex === index ? '0 0 0 5px green' : 'none')} />
                      <div style={modalStyles.imageTitle}>{image.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : activeTab === 'myself' ? (
              <div>
                <div style={modalStyles.inputContainer}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    placeholder="Search yourself..."
                    style={modalStyles.input} />
                  <button onClick={handleSearch} style={modalStyles.button}>
                    Search
                  </button>
                </div>
                <div style={modalStyles.grid}>
                  {myselfImages.map((image, index) => (
                    <div key={index} style={modalStyles.imageContainer}>
                      <img
                        src={image.link}
                        alt={`myself-image-${index}`}
                        style={modalStyles.image(index)}
                        onClick={() => handleClick(index, true)}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = '0 0 0 5px yellow')}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow =
                          clickedImageIndex === index ? '0 0 0 5px green' : 'none')} />
                      <div style={modalStyles.imageTitle}>{image.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <div style={modalStyles.inputContainer}>
                  <input
                    type="text"
                    value={youtubeSearchTerm}
                    onChange={handleYoutubeSearchInputChange}
                    placeholder="Search yourself..."
                    style={modalStyles.input} />
                  <button onClick={handleYoutubeSearch} style={modalStyles.button}>
                    Search
                  </button>
                </div>

                {!PickableClipsFlag && videos.length > 0 ? (
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {videos.map((video, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          backgroundColor: '#fff',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                          alignItems: 'center',
                          marginTop: '5px',
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = '0 0 0 5px yellow')}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow =
                          clickedImageIndex === index ? '0 0 0 5px green' : 'none')}
                        onClick={() => handleYoutubeSearchPick(video.video_id, video.title)}
                      >
                        <iframe
                          width="200"
                          height="120"
                          src={`https://www.youtube.com/embed/${video.video_id}`}
                          title={video.title}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          style={{ borderRadius: '8px', flexShrink: 0 }}
                        ></iframe>

                        <div style={{ marginLeft: '20px', flex: 1 }}>
                          <h3 style={{ fontSize: '16px', fontWeight: 'bold', margin: '0 0 10px 0', color: '#000' }}>
                            {video.title}
                          </h3>
                          <p style={{ fontSize: '14px', color: '#606060', margin: '0 0 5px 0' }}>
                            {video.channelTitle}
                          </p>
                          <p style={{ fontSize: '12px', color: '#909090' }}>{video.publishedAt}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>Search Videos to use in background.</p>
                )}
              </div>
            )}
          </>
        )}

        <br />

        <div className="button-container">
          <Button type="primary" danger onClick={onClose} style={buttonStyle}>
            Close
          </Button>

          {showRenderButton && (
            <Button type="primary" onClick={handleRender} style={buttonStyle}>
              Render
            </Button>
          )}
        </div>
      </div>
    </div>
    <SubClipModal isOpen={openSubClipModal} onClose={closeSubClipModal} video = {video} youtube_video_id = {YoutubeVideoIdSubCliping} dividedClips = {dividedClips} imageText = {imageText} chosenYoutubeVideoTitle = {chosenYoutubeVideoTitle} fetchSentence = {fetchSentence} setActiveTab = {setActiveTab}/>
    <Spin spinning={loadingVideoSearch} fullscreen tip="Generating videos..." size="small" />
    
    </>
  );
  
}

export default Modal;
