import React, { useState } from 'react';
import './App.css';
import './fonts.css';
import Modal from './Modal';
import { BASE_URL } from './config'; // Import the BASE_URL from the config file
import { Button, Input, Space, Spin } from 'antd';
import { Modal as OptionsModal } from 'antd';

const { TextArea } = Input;

function App() {
  const [topic, setTopic] = useState('');
  const [story, setStory] = useState('');
  const [video, setVideo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false); 
  const [isStoryGenerating, setIsStoryGenerating] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [useSecondaryModel, setUseSecondaryModel] = useState(false);
  const token = localStorage.getItem('access_token_orion_editor');

  const handleGenerateOptions = () => {
    const trimmedTopic = topic.trim();

    // Check if the trimmed topic is not empty
    if (trimmedTopic === '') {
      alert('Please enter a valid topic before generating the script.');
      return;
    }
    setIsOptionsModalOpen(true);
  };

  // const handleGenerateStory = (type) => {
  //   setIsGenerating(true);
  //   const url = `${BASE_URL}/generate_story`; 
  
  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`,
  //     },
  //     body: JSON.stringify({ topic, script_type: type }),
  //   })
  //     .then(async (response) => {
  //       if (response.status === 403) {
  //         window.location.href = '/login';
  //         return;
  //       }
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch the story');
  //       }
  
  //       const reader = response.body.getReader();
  //       const decoder = new TextDecoder();
  //       let result = '';
  //       const regex = /.*?~{3,}.+?~{3,}.*/;
  //       let checkOnce = false;
  //       let buffer = ''; // Buffer to hold incomplete chunks
  
  //       while (true) {
  //         const { done, value } = await reader.read();
  //         if (done) break;
  
  //         // Decode the incoming value
  //         const decodedValue = decoder.decode(value, { stream: true });
          
  //         // Append the decoded value to the buffer
  //         buffer += decodedValue;
  
  //         // Split the buffer into complete JSON objects using a regex or boundary detection
  //         const jsonObjects = buffer.split(/(?<=})/);  // Split on closing braces
  
  //         // Process each object except the last one (which may be incomplete)
  //         for (let i = 0; i < jsonObjects.length - 1; i++) {
  //           try {
  //             const jsonObject = JSON.parse(jsonObjects[i]);
  //             console.log("Parsed JSON object:", jsonObject);

  //             console.log("finish reason ", jsonObject.finish_reason)
  //             // Append content to the result
  //             result += jsonObject.content;

              
  
  //             // Clean the result by removing tildes and append to the story
  //             const cleanedStory = jsonObject.content.replace(/~/g, '');
  //             setStory((prevStory) => prevStory + cleanedStory);
  
  //             // Stop generating if we detect the termination pattern once
  //             if (regex.test(result) && !checkOnce) {
  //               setIsGenerating(false); 
  //               checkOnce = true;         
  //             }
  //           } catch (error) {
  //             console.error('Error parsing JSON chunk:', error);
  //           }
  //         }
  
  //         // Keep the last, potentially incomplete part in the buffer
  //         buffer = jsonObjects[jsonObjects.length - 1];
  //       }
  //       console.log("buffer", buffer)
  //       if(JSON.parse(buffer).finish_reason == "content_filter"){
  //         alert("Censored.")
  //       }
  
  //       setIsStoryGenerating(false);
  //       console.log('Story Generation Completed.');
  //     })
  //     .catch((error) => {
  //       setIsGenerating(false);
  //       console.error('Error:', error);
  //     });
  // };
  
  const handleGenerateStory = (type) => {
    setStory('');
    setUseSecondaryModel(false);
    setIsGenerating(true);
    const url = `${BASE_URL}/generate_story`; 
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ topic, script_type: type }),
    })
      .then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/login';
          return;
        }
        if (!response.ok) {
          throw new Error('Failed to fetch the story');
        }
  
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = '';
        const regex = /.*?~{3,}.+?~{3,}.*/;
        let checkOnce = false;
        let buffer = ''; // Buffer to hold incomplete chunks
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
  
          // Decode the incoming value
          const decodedValue = decoder.decode(value, { stream: true });
          
          // Append the decoded value to the buffer
          buffer += decodedValue;
  
          // Split the buffer into complete JSON objects using a regex or boundary detection
          const jsonObjects = buffer.split(/(?<=})/);  // Split on closing braces
  
          // Process each object except the last one (which may be incomplete)
          for (let i = 0; i < jsonObjects.length - 1; i++) {
            try {
              const jsonObject = JSON.parse(jsonObjects[i]);
              console.log("Parsed JSON object:", jsonObject);

              console.log("finish reason ", jsonObject.finish_reason)
              // Append content to the result
              result += jsonObject.content;

              
  
              // Clean the result by removing tildes and append to the story
              const cleanedStory = jsonObject.content.replace(/~/g, '');
              setStory((prevStory) => prevStory + cleanedStory);
  
              // Stop generating if we detect the termination pattern once
              if (regex.test(result) && !checkOnce) {
                setIsGenerating(false); 
                checkOnce = true;         
              }
            } catch (error) {
              console.error('Error parsing JSON chunk:', error);
            }
          }
  
          // Keep the last, potentially incomplete part in the buffer
          buffer = jsonObjects[jsonObjects.length - 1];
        }
        console.log("buffer", buffer)
        if(JSON.parse(buffer).finish_reason == "content_filter"){
          handleGenerateStorySecondary(type);
          return;
        }
  
        setIsStoryGenerating(false);
        console.log('Story Generation Completed.');
      })
      .catch((error) => {
        setIsGenerating(false);
        console.error('Error:', error);
      });
  };

  const handleGenerateStorySecondary = (type) => {
    setUseSecondaryModel(true);
    setStory('');
    setIsGenerating(true);
    const url = `${BASE_URL}/generate_story_secondary`; 
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ topic, script_type: type}),
    })
      .then(async (response) => {
        if (response.status === 403) {
          window.location.href = '/login';
          return;
        }
        if (!response.ok) {
          throw new Error('Failed to fetch the story');
        }
  
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = '';
        const regex = /.*?~{3,}.+?~{3,}.*/;
        let checkOnce = false;
        let buffer = ''; // Buffer to hold incomplete chunks
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
  
          // Decode the incoming value
          const decodedValue = decoder.decode(value, { stream: true });
          
          // Append the decoded value to the buffer
          buffer += decodedValue;
  
          // Split the buffer into complete JSON objects using a regex or boundary detection
          const jsonObjects = buffer.split(/(?<=})/);  // Split on closing braces
  
          // Process each object except the last one (which may be incomplete)
          for (let i = 0; i < jsonObjects.length - 1; i++) {
            try {
              const jsonObject = JSON.parse(jsonObjects[i]);
              console.log("Parsed JSON object:", jsonObject);

              console.log("finish reason ", jsonObject.finish_reason)
              // Append content to the result
              result += jsonObject.content;

              
  
              // Clean the result by removing tildes and append to the story
              const cleanedStory = jsonObject.content.replace(/~/g, '');
              setStory((prevStory) => prevStory + cleanedStory);
  
              // Stop generating if we detect the termination pattern once
              if (regex.test(result) && !checkOnce) {
                setIsGenerating(false); 
                checkOnce = true;         
              }
            } catch (error) {
              console.error('Error parsing JSON chunk:', error);
            }
          }
  
          // Keep the last, potentially incomplete part in the buffer
          buffer = jsonObjects[jsonObjects.length - 1];
        }
        console.log("buffer", buffer)
        // if(JSON.parse(buffer).finish_reason == "content_filter"){
        //   alert("Sorry we have the topic has been  censored by the langauge model.")
        // }
  
        setIsStoryGenerating(false);
        console.log('Story Generation Completed.');
      })
      .catch((error) => {
        setIsGenerating(false);
        console.error('Error:', error);
      });
  };
  
  

  const handleGenerateAudio = () => {
    const trimmedTopic = topic.trim();

    if (trimmedTopic === '') {
      alert('Please enter a valid topic before generating the Assets.');
      return;
    }

    setIsGenerating(true);
    const url = `${BASE_URL}/generate_audio`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ topic: topic, story: story , use_secondary_model : useSecondaryModel}),
    })
      .then((response) => {
        if (response.status === 403) {
          window.location.href = '/login';
          return;
        }
        return response.json();
      })
      .then((data) => {
        console.log('data.video', data.video);
        setVideo(data.video);
        setIsGenerating(false);
        setIsModalOpen(true);
      })
      .catch((error) => {
        setIsGenerating(false);
        console.error('Error:', error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTopicExplanation = () => {
    setIsOptionsModalOpen(false);
    handleGenerateStory('topic_explanation');
  };

  const handleStoryTelling = () => {
    setIsOptionsModalOpen(false);
    handleGenerateStory('story_telling');
  };

  return (
    <div className="App">
      <div style={{ fontFamily: 'Playfair Display', color: 'white', fontSize: '2em', textAlign: 'center' }}>
        <i>
          <span>Orion the editor.</span>
        </i>
      </div>
      <div className="app-section">
        <Space.Compact style={{ width: '100%' }}>
          <Input placeholder="Enter Video Topic" onChange={(e) => setTopic(e.target.value)} />
          <Button type="primary" onClick={handleGenerateOptions}>
            Generate Script
          </Button>
        </Space.Compact>
      </div>

      <div className="app-section">
        <TextArea
          placeholder="Video Story Will Be Generated Here"
          autoSize={{ minRows: 15, maxRows: 20 }}
          value={story.replace(/\\n/g, '\n')}
          onChange={(e) => setStory(e.target.value)}
        />
      </div>

      <Button type="primary" onClick={handleGenerateAudio}>
        Generate Assets
      </Button>
      <Modal isOpen={isModalOpen} onClose={closeModal} video={video} />
      <Spin spinning={isGenerating} fullscreen tip="Generating ..." size="large" />

      <OptionsModal
        open={isOptionsModalOpen}
        onCancel={() => {
          setIsOptionsModalOpen(false);
        }}
        title="What type of YouTube Script would you like me to generate?"
        footer={[]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleTopicExplanation}>Topic Explanation</Button>
          <span>|</span>
          <Button onClick={handleStoryTelling}>Story Telling</Button>
        </div>
      </OptionsModal>
    </div>
  );
}

export default App;
