// config.js
// export const IP = 'http://127.0.0.1';//for local server
// export const IP = 'http://13.59.179.91';
// export const IP = 'http://api.oriontheeditor.com'; //For local development

// export const IP = 'http://127.0.0.1';//for local server
// export const BASE_URL = `${IP}:8000` // Define your base URL here
// export const REOMOTION_ADDITIONAL_NGINX = ``

// export const IP = 'https://api.oriontheeditor.com'; //For deployment
// export const BASE_URL = `${IP}`
// export const REOMOTION_ADDITIONAL_NGINX = `node/`


let IP;
let BASE_URL;
let REMOTION_ADDITIONAL_NGINX;
let REMOTION_SERVER_URL;


let LOCAL_SERVER = false;
if(LOCAL_SERVER ===  true){
    IP = 'http://127.0.0.1';
    BASE_URL = `${IP}:8000`;
    REMOTION_ADDITIONAL_NGINX = '';
    REMOTION_SERVER_URL = `${IP}:4505`;
}else{
    IP = 'https://api.oriontheeditor.com';
    BASE_URL = `${IP}`;
    REMOTION_ADDITIONAL_NGINX = 'node/';
    REMOTION_SERVER_URL = `${IP}`;
}

export { IP, BASE_URL, REMOTION_ADDITIONAL_NGINX, REMOTION_SERVER_URL};


