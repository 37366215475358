import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import App from './App';
import Login from './Login';
import { BASE_URL } from './config';

function Main() {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Skip authentication check if the current route is /login
        if (location.pathname === '/login') {
          setIsAuthChecked(true);
          return;
        }

        const token = localStorage.getItem('access_token_orion_editor');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};

        // First, check the token validity
        const tokenResponse = await axios.post(`${BASE_URL}/test_token`, {}, { headers });
        const { redirect_login } = tokenResponse.data;

        if (redirect_login === undefined || redirect_login === true) {
          navigate('/login');
        } else {
          // Then, check how much time is left on the token
          const timeCheckResponse = await axios.post(`${BASE_URL}/check_token_time`, { access_token: token }, { headers });
          const { valid_hours_left } = timeCheckResponse.data;

          if (valid_hours_left === true) {
            // Allow access to the requested route
            setIsAuthChecked(true);
          } else {
            // Token is expiring soon, redirect to login
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();
  }, [navigate, location.pathname]);

  if (!isAuthChecked) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      {/* Add more routes as needed */}
    </Routes>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default AppWrapper;
